import { SanityProductVariant } from 'graphql-types';

export type CartItemType = 'Sub' | 'Sku' | 'Bundle'; // (Sub, Sku, Bundle)

export interface CartItem {
  id: string; // (Plan, Sku, Bundle)
  quantity: number;
  price: string;
  totalPrice: string; // price * quantity
  origPrice: string;
  discount: string;
  totalDiscount: string; // discount * quantity
  totalOrigPrice: string; // origPrice * quantity
  productType?: 'Supplements' | 'Gear' | 'Digital Subscription';
  type: CartItemType;
  isDigital: boolean;
  name: string;
  couponApplied?: boolean; // If the coupon was applied to this item (will always be false for bundles)
  variant?: SanityProductVariant;
  subItems?: CartItem[];
  imageUrl?: string;
}

export type UpdateCartItem = Pick<CartItem, 'id' | 'quantity'> & {
  coupon?: string;
};

export type UpdateCart = {
  coupon?: string;
  items: UpdateCartItem[];
};

export enum CartAdjustmentReason {
  AlreadySubscribed = 'ALREADY_SUBSCRIBED',
  OnlyOneDigitalSubAllowed = 'ONLY_ONE_DIGITAL_SUB_ALLOWED',
  NotAvailableForLocale = 'NOT_AVAILABLE_FOR_LOCALE',
  InvalidProduct = 'INVALID_PRODUCT',
  InvalidBundleReplacedWithDigital = 'INVALID_BUNDLE_REPLACED_WITH_DIGITAL',
}
export type CartAdjustment = {
  itemId: string;
  reason: CartAdjustmentReason;
};

export interface Cart {
  coupon?: string;
  price: string; // price including discounts/coupons
  origPrice: string; // origPrice sum of all the cart items
  discount: string;
  discountPercent: string; // Need to confirm if this needed
  items: CartItem[];
  cartAdjustments?: CartAdjustment[];
}

export type DigitalSubscriptionWarningModalMessage = {
  type: 'DigitalSubLimit';
  reasons: CartAdjustmentReason[];
};
