import React, { useEffect, useState } from 'react';
import { ToastContainer, ToastContent, ToastText } from './Toast.styles';
import circledCheckmark from 'svg/checkmark-circle.svg';
import exclamation from 'svg/exclamation.svg';
import { Svg } from 'components/Common/Svg';

const STATUS_ICONS = {
  success: circledCheckmark,
  failure: exclamation,
};

type Config = {
  text: string;
  bottom: string | number;
  status?: 'success' | 'failure';
  duration?: number;
};

export type ToastConfig = Omit<Config, 'bottom'>;

export function displayToast(config: ToastConfig) {
  const event = new CustomEvent('toast', { detail: config });
  window.dispatchEvent(event);
}

export const Toast: React.FC = () => {
  const [config, setToastConfig] = useState<Config | null>(null);

  useEffect(() => {
    const toastHandler = (e: CustomEvent<Config>) => {
      let bottom: string | number = 0;
      // #region Detect if sticky element is on bottom
      const stickyElement = document.querySelector('.stky');
      if (stickyElement) {
        const { height, bottom: stickyBottom } = getComputedStyle(
          stickyElement
        );
        bottom = stickyBottom === '0px' ? height : '0px';
      }
      // #endregion

      const { text, duration = 3000, status } = e.detail;
      setToastConfig({ text, status, bottom, duration });
      setTimeout(() => {
        setToastConfig(null);
      }, duration);
    };

    window.addEventListener('toast', toastHandler);
    return () => window.removeEventListener('toast', toastHandler);
  });

  return (
    config && (
      <ToastContainer bottom={config.bottom}>
        <ToastContent>
          <Svg
            icon={STATUS_ICONS[config.status || 'success']}
            height={'24px'}
            width={'24px'}
          />
          <ToastText>{config.text}</ToastText>
        </ToastContent>
      </ToastContainer>
    )
  );
};
