import React, { useEffect } from 'react';
import { CartProvider, useCartContext } from 'Context/Cart/Cart.context';
import { logError } from 'helpers/Log';
import { CartProductInformation } from '../CartProductInformation/CartProductInformation';
import { Cart, UpdateCartItem } from 'api/cart/cart.api.model';
import { StickyBuyButtonCta } from 'components/Product/StickyBuyButtonCta/StickyBuyButtonCta';
import { createCheckoutUrl } from 'utils/url';
import { awaitClick, logEvent } from 'helpers/Amplitude';
import { ShippingInfo } from 'components/Product/ShippingInfo/ShippingInfo';
import { CreditCard } from 'components/CreditCards/CreditCards';
import { SummaryInfo } from 'components/SummaryInfo/SummaryInfo';
import {
  CartProductInformationStyles,
  CheckoutBoxStyles,
  CreditCardContainerStyles,
  SummaryContainerStyles,
} from './CartPage.styles';
import {
  Block as LadderBlock,
  CartBlockRenderer as LadderCartBlockRenderer,
} from 'templates/ladder/CartPage/CartPage.blocks';
import { displayToast, Toast } from 'components/Toast/Toast';
import {
  Block as OpenfitBlock,
  CartBlockRenderer as OpenfitCartBlockRenderer,
} from 'templates/openfit/CartPage/CartPage.blocks';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

type CartBlockRenderer = LadderCartBlockRenderer | OpenfitCartBlockRenderer;

function getCheckoutUrl(cart: Cart): string {
  const products: string[] = [];

  cart?.items.forEach((cartItem) => {
    const itemStripeId = cartItem.variant?.sku;
    if (itemStripeId) {
      products.push(itemStripeId + `:${cartItem.quantity || 1}`);
    }

    if (cartItem.subItems) {
      products.push(cartItem.id + `:${cartItem.quantity || 1}`);
    }
  });

  return createCheckoutUrl({
    ...(cart?.coupon && { coupon: cart.coupon }),
    product: products,
    cart: true,
  });
}

async function logProceedToCheckout(eventName: string, cart: Cart) {
  const products = cart.items.reduce(
    (previousValue, { subItems, name, price, variant, id, quantity }) => {
      previousValue.push({
        name,
        price: price,
        readablePayPeriod: variant?.interval || '',
        skus: subItems ? subItems.map(({ id }) => id) : [id],
        quantity,
      });
      return previousValue;
    },
    [] as {
      name: string;
      price: string;
      readablePayPeriod: string;
      skus: string[];
      quantity: number;
    }[]
  );

  await logEvent(eventName, {
    price: cart.price,
    quantity:
      cart?.items?.reduce(
        (previousItem, curr) => (previousItem += curr.quantity || 0),
        0
      ) || 0,
    products,
    promoCode: cart.coupon,
    currencyCode: 'USD',
    currencySymbol: '$',
    url_path: window.location.pathname,
  });
}

const CartPageContent: React.FC<{
  blocks?: LadderBlock[] | OpenfitBlock[];
  BlockRenderer?: CartBlockRenderer;
}> = ({ blocks, BlockRenderer }) => {
  const { addCartItem, cart, cartLoaded } = useCartContext();

  useEffect(() => {
    const addCartItemEventListener = async ({ data }: MessageEvent) => {
      let parsedData: {
        type: 'CartItem';
        data: UpdateCartItem;
        inIframe: boolean;
      } | null = null;
      try {
        parsedData = JSON.parse(data);
      } catch {}

      if (parsedData && parsedData.type === 'CartItem') {
        const success = await addCartItem(parsedData.data);

        if (!parsedData.inIframe) {
          // Scroll Cart Page to Top when item is added
          window.scrollTo(0, 0);
          return;
        }

        const quantity = parsedData.data.quantity;
        if (!success) {
          displayToast({
            text: `Sorry, this item can’t be added at this time.`,
            status: 'failure',
          });
        } else if (quantity > 0) {
          displayToast({ text: `${quantity} item added to your cart.` });
        }
      }
    };
    window.addEventListener('message', addCartItemEventListener);
    // Trigger scroll on render to initiate sticky button
    window.dispatchEvent(new Event('scroll'));
    return () =>
      window.removeEventListener('message', addCartItemEventListener);
  }, [addCartItem]);

  useEffect(() => {
    if (cartLoaded) {
      window.parent.postMessage('CartMounted', '*');
    }
  }, [cartLoaded]);

  const handleBuyButton: (
    amplitudeEventName: string
  ) => React.ReactEventHandler = (amplitudeEventName) =>
    awaitClick(async () => {
      if (!cart) {
        logError('Proceed To Checkout: Cart undefined');
        return;
      }
      await logProceedToCheckout(amplitudeEventName, cart);
      window.parent.location.assign(getCheckoutUrl(cart));
    });

  const hasPhysical = Boolean(cart?.items?.find((item) => !item.isDigital));
  return (
    <div data-testid="CartData" style={{ paddingBottom: '60px' }}>
      <CartProductInformationStyles>
        <CartProductInformation />
      </CartProductInformationStyles>
      <CheckoutBoxStyles>
        <SummaryContainerStyles>
          <SummaryInfo
            subtotalPrice={Number(cart?.origPrice || 0)}
            estimatedTotal={Number(cart?.price || 0)}
            totalSavings={Number(cart?.discount || 0) * -1}
          />
        </SummaryContainerStyles>
        <StickyBuyButtonCta
          textOverride="Proceed to Checkout"
          handleBuyClick={handleBuyButton(
            'Cart Page: Proceed to Checkout Clicked'
          )}
          handleBuyStickyClick={handleBuyButton(
            'Cart Page: Sticky Proceed to Checkout Clicked'
          )}
        />
        <CreditCardContainerStyles>
          <CreditCard height={'24px'} />
        </CreditCardContainerStyles>
        <ShippingInfo
          hasFreeShipping={hasPhysical}
          hasSubscription={Boolean(
            cart?.items?.find(
              (item) =>
                item.isDigital ||
                (item.variant?.recurring && item.variant.interval) ||
                item.subItems?.find(
                  (subItem) =>
                    subItem.isDigital ||
                    (subItem.variant?.recurring && subItem.variant.interval)
                )
            )
          )}
          moneyBackGuaranteeDays={hasPhysical ? 30 : 0}
        />
      </CheckoutBoxStyles>
      {(blocks as LadderBlock[])
        ?.map((block, idx) => {
          const Renderer = BlockRenderer as LadderCartBlockRenderer;
          return (
            block &&
            Renderer && (
              <Renderer
                key={idx + (cart?.items?.length || 0)}
                block={block}
                cart={cart}
              />
            )
          );
        })
        .map((block, index) => (
          <ImageLoadingWrapper key={index} eager={index < 2} children={block} />
        ))}
    </div>
  );
};

export const CartPage: React.FC<{
  blocks?: LadderBlock[] | OpenfitBlock[];
  BlockRenderer?: CartBlockRenderer;
}> = ({ blocks, BlockRenderer }) => (
  <>
    <CartProvider>
      <CartPageContent blocks={blocks} BlockRenderer={BlockRenderer} />
    </CartProvider>
    <Toast />
  </>
);
