import React, { FunctionComponent } from 'react';
import {
  StyledFieldSelect,
  StyledFieldSelectWrap,
  StyledFieldSelectContainer,
} from './Select.styles';

// #region Select Option
export type SelectOptionProps = {
  text: string;
} & React.DetailedHTMLProps<
  React.OptionHTMLAttributes<HTMLOptionElement>,
  HTMLOptionElement
>;

const SelectOption: FunctionComponent<SelectOptionProps> = ({
  text,
  ...props
}) => {
  return <option {...props}>{text}</option>;
};
// #endregion

// #region Select
export type SelectProps = {
  items: SelectOptionProps[];
  label?: string;
  placeholder?: string;
  isBold?: boolean;
} & React.OptionHTMLAttributes<HTMLSelectElement>;

export const Select: FunctionComponent<SelectProps> = ({
  items,
  value,
  label,
  placeholder,
  className,
  ...restProps
}) => {
  const withPlaceholder = Boolean(placeholder && value === '');
  return (
    <StyledFieldSelectWrap className={className}>
      {label && <span className="labelDefault">{label}</span>}
      <StyledFieldSelectContainer className="selectContainer">
        <StyledFieldSelect
          value={value}
          className="innerField"
          withPlaceholder={withPlaceholder}
          {...(restProps as any)}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {items.map(({ id, ...optionProps }, index) => (
            <SelectOption key={id || index} {...optionProps} />
          ))}
        </StyledFieldSelect>
      </StyledFieldSelectContainer>
    </StyledFieldSelectWrap>
  );
};
// #endregion
