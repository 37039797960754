import styled from 'styled-components';
import { typography } from 'styled-utils';

export const ToastContainer = styled.div<{ bottom: string | number }>`
  max-width: 383px;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  margin: 18px 16px;
  bottom: ${({ bottom }) => bottom};
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.basic800};
  color: ${({ theme }) => theme.colors.basic100};
`;

export const ToastContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 16px;
  ${({ theme }) => typography(theme.typography.default)};
`;

export const ToastText = styled.div`
  margin-left: 10px;
`;
