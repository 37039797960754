import { xSmallStyle, smallStyle } from 'components/Common/Text';
import styled from 'styled-components';

export const StyledProductInformation = styled.div`
  & > :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.basic400};
  }
`;

export const StyledCartItemContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  padding-top: 24px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const StyledCartItemImageContainer = styled.div`
  margin-right: 21px;
  position: relative;
  img {
    border-radius: 4px;
  }
  width: 85px;
  height: 85px;
  background: linear-gradient(90deg, #e8e8ea 0%, #f4f4f7 100%);
`;

export const StyledCartItemDesc = styled.div`
  ${xSmallStyle};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-right: 4px;
  width: 185px;

  h5 {
    ${smallStyle};
    font-weight: ${({ theme }) => theme.fontWeights.black};
    margin-bottom: 4px;
  }
`;

export const StyledCartItemDetails = styled.div``;

export const StyledCartItemQuantity = styled.div`
  margin-top: 8px;
`;

export const StyledQuantityLabel = styled.label`
  ${xSmallStyle};
  color: ${({ theme }) => theme.colors.basic800};
`;

export const StyledCartPriceColumn = styled.div`
  width: 80px;
  padding-left: 4px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const StyledCartItemPrice = styled.div`
  ${xSmallStyle};
  color: ${({ theme }) => theme.colors.basic800};
`;

export const StyledCartItemOrigPrice = styled.div`
  ${xSmallStyle};
  color: ${({ theme }) => theme.colors.basic600};
  text-decoration: line-through;
`;

export const StyledCartProductDetails = styled.div`
  ${xSmallStyle};
  color: ${({ theme }) => theme.colors.basic600};
`;

export const StyledRemoveButton = styled.button`
  border: none;
  background-color: inherit;
  text-decoration: underline;
  ${xSmallStyle};
  cursor: pointer;
  margin-top: auto;
  padding: 0;
  text-align: right;
  color: ${({ theme }) => theme.colors.basic800};
`;

export const StyledProductDetailBreak = styled.div`
  margin-bottom: 8px;
`;

export const StyledSubItemsSpacer = styled.div`
  height: 32px;
`;
