import React, { createContext, useContext } from 'react';
import { logError } from 'helpers/Log';
import { getAuthorized } from 'helpers/Customer';
import { useGetCart } from 'helpers/cart/Cart.helper';
import { updateCart as updateCartRest } from 'api/cart/cart.api';
import {
  UpdateCartItem,
  Cart,
  CartItem,
  CartAdjustmentReason,
  DigitalSubscriptionWarningModalMessage,
} from 'api/cart/cart.api.model';
import { CART_COUNT_COOKIE, CART_STORAGE_KEY } from 'Constants/general';
import { setStorageItemWithExpiration } from 'helpers/storage';
import Cookies from 'js-cookie';
import { logEvent } from 'helpers/Amplitude';

export interface CartContextInterface {
  cart?: Cart;
  replaceCartItem: (cartItem: UpdateCartItem) => Promise<boolean>;
  addCartItem: (cartItem: UpdateCartItem) => Promise<boolean>;
  cartLoaded: boolean;
}

export const CartContext = createContext<CartContextInterface>(
  {} as CartContextInterface
);

export const useCartContext = () => useContext(CartContext);

const subRemovalReasons: CartAdjustmentReason[] = Object.values(
  CartAdjustmentReason
);

export const CartProvider: React.FC = ({ children }) => {
  const { cart, setCart: setCartState, cartLoaded } = useGetCart();

  const setCart = (updatedCart: Cart) => {
    const cartCount =
      updatedCart?.items?.reduce(
        (previousItem, curr) => (previousItem += curr.quantity || 0),
        0
      ) || 0;
    if (!getAuthorized()) {
      try {
        setStorageItemWithExpiration(
          CART_STORAGE_KEY,
          JSON.stringify(updatedCart),
          30
        );
      } catch (e) {
        logError(e);
      }
      Cookies.set(CART_COUNT_COOKIE, cartCount.toString());
    }
    setCartState(updatedCart);
    window.parent.postMessage(
      JSON.stringify({ type: 'CartCount', data: cartCount }),
      '*'
    );
  };

  const addCartItem = (item: UpdateCartItem) => updateCartItem(item, true);

  const replaceCartItem = (item: UpdateCartItem) => updateCartItem(item);

  const updateCartItem = async (
    { id, quantity, coupon }: UpdateCartItem,
    isAddition = false
  ) => {
    try {
      if (cart?.items) {
        const existingItem = cart.items.find((cartItem) => cartItem.id === id);

        if (existingItem) {
          existingItem.quantity = isAddition
            ? quantity + existingItem.quantity
            : quantity;
        } else {
          cart.items.push({ id, quantity } as CartItem);
        }
      }

      const items = (cart?.items || ([{ id, quantity }] as CartItem[])).map(
        (item) => ({
          id: item.id,
          quantity: item.quantity,
        })
      );

      // Update Cart
      const updatedCart = await updateCartRest({
        coupon: coupon || cart?.coupon || undefined,
        items,
      });
      setCart(updatedCart as Cart);

      const subAdjustments = updatedCart.cartAdjustments?.filter((adjustment) =>
        subRemovalReasons.includes(adjustment.reason)
      );

      if (subAdjustments) {
        const message: DigitalSubscriptionWarningModalMessage = {
          type: 'DigitalSubLimit',
          reasons: subAdjustments.map((subAdjustment) => subAdjustment.reason),
        };
        window.parent.postMessage(JSON.stringify(message), '*');
      } else {
        window.parent.COMMON_HEADER?.toggleCartDrawer(
          Boolean(updatedCart?.items?.length)
        );
      }

      if (!Boolean(updatedCart.items?.length)) {
        logEvent('Cart Page: Empty');
      }
      return true;
    } catch (e) {
      logError(e);
      return false;
    }
  };

  return (
    <CartContext.Provider
      value={{ addCartItem, replaceCartItem, cart, cartLoaded }}
    >
      {children}
    </CartContext.Provider>
  );
};
