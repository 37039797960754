import React from 'react';
import {
  SummaryInfoStyle,
  Table,
  SpecialRowStyled,
  BoldRowStyled,
  Info,
  LineStyled,
} from './SummaryInfo.styled';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';

export type SummaryInfoProps = {
  subtotalPrice?: number;
  totalSavings?: number;
  taxesAndFees?: number;
  estimatedTotal?: number;
};

type Priced = React.FC<{ price: SummaryInfoProps['subtotalPrice'] }>;

export const SummaryInfo: React.FC<SummaryInfoProps> = ({
  subtotalPrice,
  totalSavings,
  taxesAndFees,
  estimatedTotal,
}) => {
  const hasSubtotal = !!subtotalPrice;
  const hasEstimatedTotal = !!estimatedTotal;
  return (
    <SummaryInfoStyle>
      <Table>
        <tbody>
          {hasSubtotal && <SubtotalInfo price={subtotalPrice} />}
          <Row name={'Shipping & Handling'} value={'FREE'} />
          {Boolean(totalSavings) && <TotalSavings price={totalSavings} />}
          {hasSubtotal && <TaxAndFees price={taxesAndFees} />}
          {hasEstimatedTotal && <Total price={estimatedTotal} />}
        </tbody>
      </Table>
    </SummaryInfoStyle>
  );
};

const SubtotalInfo: Priced = ({ price }) => (
  <tr>
    <th>Order Subtotal</th>
    <td>{getPriceFormatter('USD', 'en-US')(price || 0)}</td>
  </tr>
);

const TotalSavings: Priced = ({ price }) => (
  <SpecialRowStyled>
    <th>Discount</th>
    <td>{getPriceFormatter('USD', 'en-US')(price || 0)}</td>
  </SpecialRowStyled>
);
const TaxAndFees: Priced = ({ price }) => (
  <Row
    name="Taxes and fees"
    value={price ? getPriceFormatter('USD', 'en-US')(price) : '--'}
  >
    {!price && <Info>(calculated in checkout)</Info>}
  </Row>
);

const Line = () => (
  <LineStyled>
    <td colSpan={2} />
  </LineStyled>
);

const Total: Priced = ({ price }) => (
  <>
    <Line />
    <BoldRowStyled>
      <th>Estimated total</th>
      <td>{getPriceFormatter('USD', 'en-US')(price || 0)}</td>
    </BoldRowStyled>
  </>
);

const Row: React.FC<{ name: string; value: any }> = ({
  name,
  value,
  children,
}) => (
  <tr>
    <th>
      {name}
      {children}
    </th>
    <td>{value}</td>
  </tr>
);
