import styled from 'styled-components';
import { typography } from 'styled-utils';

export const DefaultWrapper = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.basic200};
`;

export const SummaryInfoStyle = styled.div`
  color: ${({ theme }) => theme.colors.basic800};
  ${({ theme }) => typography(theme.typography.small)};
`;

export const Table = styled.table`
  width: 100%;

  th,
  td {
    padding-bottom: 8px;
  }

  th {
    text-align: left;
  }

  td {
    text-align: right;
    vertical-align: bottom;
  }
`;

export const SpecialRowStyled = styled.tr`
  color: green;
  line-height: 30px;
`;

export const BoldRowStyled = styled.tr`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const LineStyled = styled.tr`
  border-color: ${({ theme }) => theme.colors.basic400};
  border-style: solid;
  border-top-width: 1px;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.gray2};
`;
