import React from 'react';
import styled from 'styled-components';
import { Select, SelectProps } from '../Select/Select';

const StyledFieldSelectQuantity = styled.div`
  display: inline-block;

  .selectContainer {
    &:after {
      background-color: ${({ theme }) => theme.select.secondary};
      width: 14px;
      height: 9px;
      top: 50%;
      transform: translateY(-50%) translateX(2px);
    }
  }

  select {
    height: 32px;
    min-width: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 13px;
  }
`;

export type SelectQuantityProps = {
  min?: number;
  max?: number;
} & Omit<SelectProps, 'items'>;

export const SelectQuantity: React.FC<SelectQuantityProps> = ({
  min = 1,
  max = 10,
  onChange,
  ...restProps
}) => {
  const items: { text: string; value: number }[] = [];
  for (let i = min; i <= max; i++) {
    items.push({ text: i.toString(), value: i });
  }

  return (
    <>
      <StyledFieldSelectQuantity>
        <Select
          onChange={onChange}
          items={items}
          isBold={false}
          {...restProps}
        />
      </StyledFieldSelectQuantity>
    </>
  );
};
