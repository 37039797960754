import React, { Fragment } from 'react';
import { SelectQuantity } from 'components/Common/SelectQuantity/SelectQuantity';
import { ProductVariant } from 'Context/Product/Product.context.model';
import { flattenSupplementVariantOptions } from 'Context/Product/Product.helper';

import {
  StyledCartItemContainer,
  StyledCartItemImageContainer,
  StyledProductDetailBreak,
  StyledCartPriceColumn,
  StyledCartItemDesc,
  StyledCartItemOrigPrice,
  StyledCartItemPrice,
  StyledCartItemQuantity,
  StyledCartProductDetails,
  StyledProductInformation,
  StyledQuantityLabel,
  StyledRemoveButton,
  StyledSubItemsSpacer,
} from './CartProductInformation.styles';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';
import { SubscriptionDuration } from 'components/SubscriptionDuration/SubscriptionDuration';
import { CartItem } from 'api/cart/cart.api.model';
import { useCartContext } from 'Context/Cart/Cart.context';
import { logEvent } from 'helpers/Amplitude';
import { displayToast } from 'components/Toast/Toast';

const CartProductDetails: React.FC<{
  cartItem: CartItem;
  addEndingBreak?: boolean;
}> = ({ cartItem, addEndingBreak }) => {
  if (!cartItem || !cartItem.variant) return null;

  const { variant, productType } = cartItem;

  if (productType === 'Supplements') {
    const { flavor, size, servingType } = flattenSupplementVariantOptions(
      variant as ProductVariant
    );
    return (
      <>
        <StyledCartProductDetails>
          {flavor && (
            <>
              {flavor.name}
              <br />
            </>
          )}
          {size && (
            <>
              {size}-{servingType}
              <br />
            </>
          )}
          {variant?.recurring && variant.interval && (
            <>
              Subscription: <SubscriptionDuration variant={variant} />
              <br />
            </>
          )}
        </StyledCartProductDetails>
        {addEndingBreak && <StyledProductDetailBreak />}
      </>
    );
  }

  if (productType === 'Digital Subscription') {
    return (
      <StyledCartProductDetails>
        {variant.trialPeriodDays && (
          <>
            {variant.trialPeriodDays}-Day Free Trial
            <br />
          </>
        )}
        {variant.recurring && variant.interval && (
          <>
            Subscription: <SubscriptionDuration variant={variant} />
            <br />
            {addEndingBreak && <StyledProductDetailBreak />}
          </>
        )}
      </StyledCartProductDetails>
    );
  }
  return null;
};

function hasDigitalInSubItems(subItems: CartItem[] | undefined) {
  return Boolean(
    subItems &&
      subItems.find((subItem) => subItem.productType === 'Digital Subscription')
  );
}
const format = getPriceFormatter('USD', 'en-US');
export const CartProductInformation: React.FC = () => {
  const { cart, replaceCartItem } = useCartContext();

  if (!cart || !cart.items || !cart.items.length) {
    return null;
  }

  return (
    <StyledProductInformation>
      {cart.items.map((cartItem) => {
        const { name, imageUrl, variant, subItems, quantity, id } = cartItem;

        const price = Number(cartItem.price || '0');
        const origPrice = Number(cartItem.origPrice || '0');
        const cartItemRemoveHandler = async () => {
          try {
            await replaceCartItem({ id, quantity: 0 });
            displayToast({ text: `1 item removed to your cart.` });
            logEvent('Cart Page: Product Removed', {
              name: cartItem.name,
              skus: cartItem.subItems
                ? cartItem.subItems.map(
                    (subItem) => subItem?.variant?.sku || ''
                  )
                : [cartItem.variant?.sku || ''],
              price: cartItem.price,
              promoCode: cart.coupon,
            });
          } catch (e) {
            displayToast({
              text: `Sorry, this item can’t be removed at this time.`,
              status: 'failure',
            });
          }
        };
        return (
          <StyledCartItemContainer key={id}>
            <StyledCartItemImageContainer>
              {imageUrl && (
                <img
                  alt={name}
                  src={imageUrl + `?w=85&h=85&fit=crop`}
                  height={85}
                  width={85}
                />
              )}
            </StyledCartItemImageContainer>
            <StyledCartItemDesc>
              <h5 data-test-id={`product-title-${name}`}>{name}</h5>
              {variant && <CartProductDetails cartItem={cartItem} />}
              {subItems &&
                subItems.map((subItem, idx) => (
                  <Fragment key={subItem.id}>
                    {subItem.name}
                    <br />
                    {!subItem.name.toUpperCase().includes('FREE') && (
                      <CartProductDetails
                        cartItem={subItem}
                        addEndingBreak={
                          subItems.length > 1 && idx !== subItems.length - 1
                        }
                      />
                    )}
                  </Fragment>
                ))}
              {!hasDigitalInSubItems(subItems) && (
                <StyledCartItemQuantity data-test-id="cart-quantity">
                  <StyledQuantityLabel>Qty: </StyledQuantityLabel>
                  <SelectQuantity
                    color="basic600"
                    onChange={(e) => {
                      replaceCartItem({
                        id,
                        quantity: Number(e.currentTarget.value),
                      });
                      logEvent('Cart Page: Product Updated', {
                        name: cartItem.name,
                        skus: cartItem.subItems
                          ? cartItem.subItems.map(
                              (subItem) => subItem?.variant?.sku || 'Unknown'
                            )
                          : [cartItem.variant?.sku || 'Unknown'],
                        price: cartItem.price,
                        promoCode: cart.coupon,
                      });
                    }}
                    value={quantity}
                  />
                </StyledCartItemQuantity>
              )}
              {subItems && <StyledSubItemsSpacer />}
            </StyledCartItemDesc>
            <StyledCartPriceColumn>
              <StyledCartItemPrice>
                {price > 0 && format(price)}
              </StyledCartItemPrice>
              {origPrice > 0 && price > 0 && origPrice !== price && (
                <StyledCartItemOrigPrice>
                  {format(origPrice)}
                </StyledCartItemOrigPrice>
              )}
              <StyledRemoveButton onClick={cartItemRemoveHandler}>
                Remove
              </StyledRemoveButton>
            </StyledCartPriceColumn>
          </StyledCartItemContainer>
        );
      })}
    </StyledProductInformation>
  );
};
