import styled from 'styled-components';
import { flexDirection, width } from 'styled-utils';
import { Svg } from 'components/Common/Svg';

export const ShippingInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexDirection({ flexDirection: ['column', 'row'] })};
`;

export const ShippingInfoItem = styled.div`
  padding-right: 2rem;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.basic600};
  margin-bottom: 1rem;
  ${width({ width: [1, 'auto'] })};
`;

export const InfoSvg = styled(Svg)`
  flex-shrink: 0;
  font-size: 24px;
  height: 19px;
  margin-right: 1rem;
`;

export const CalendarContainer = styled.div<{
  hasFreeShipping?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const CalendarDays = styled.span`
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  left: 5px;
  top: 7px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
