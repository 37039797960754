import React from 'react';
import { CANCEL_TEXT } from 'Constants';
import {
  CalendarContainer,
  CalendarDays,
  InfoSvg,
  ShippingInfoItem,
  ShippingInfoWrapper,
} from './ShippingInfo.styles';
import truckSvg from 'svg/truck.svg';
import calendarSvg from 'svg/calendar.svg';
import cancelSvg from 'svg/cancel.svg';

export const ShippingInfo: React.FC<{
  hasFreeShipping?: boolean;
  moneyBackGuaranteeDays?: number;
  hasSubscription?: boolean;
}> = ({ hasFreeShipping, moneyBackGuaranteeDays, hasSubscription }) => (
  <ShippingInfoWrapper>
    {hasFreeShipping && (
      <ShippingInfoItem>
        <InfoSvg icon={truckSvg} useFontSize />
        Free Shipping
      </ShippingInfoItem>
    )}
    {Boolean(moneyBackGuaranteeDays) && (
      <ShippingInfoItem>
        <CalendarContainer hasFreeShipping={hasFreeShipping}>
          <CalendarDays>{moneyBackGuaranteeDays}</CalendarDays>
          <InfoSvg icon={calendarSvg} useFontSize />
        </CalendarContainer>
        {moneyBackGuaranteeDays}-Day Money-Back Guarantee
        {!hasFreeShipping && '(less S&H)'}
      </ShippingInfoItem>
    )}
    {hasSubscription && (
      <ShippingInfoItem>
        <InfoSvg icon={cancelSvg} useFontSize />
        {CANCEL_TEXT}
      </ShippingInfoItem>
    )}
  </ShippingInfoWrapper>
);
