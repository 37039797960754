import { Button } from 'components/Common/Button';
import styled from 'styled-components';
import { maxWidth } from 'styled-system';
import { border, boxShadow, display, flexDirection, space } from 'styled-utils';

export const StickyBuyButtonTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
`;

export const StickyBuyButtonCopy = styled.div`
  ${display({ display: ['none', 'flex'] })};
  flex-flow: row;
  justify-content: center;
  align-items: center;
`;

export const StickyBuyButtonDetail = styled.div`
  margin-left: 1em;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black};
  line-height: 20px;
`;

export const StickyBuyButtonImageContainer = styled.div`
  background: linear-gradient(90deg, #e8e8ea 0%, #f4f4f7 100%);
  width: 72px;
  height: 100%;
`;

export const BuyButtonDivider = styled.span`
  border-color: ${({ theme }) => theme.colors.basic100};
  &:hover {
    border-color: ${({ theme }) => theme.colors.red400};
  }
  border-left: 1px solid;
  margin: 0 1rem;
`;

export const BuyButton = styled(Button)`
  width: 100%;
  margin: 3rem 0 3rem;
  @media (max-width: 639px) {
    margin: 0 0 2rem;
  }
  @media (min-width: 993px) {
    margin: 7rem 0 5rem;
  }
`;

export const StickyBuyButton = styled(Button)`
  ${maxWidth({ maxWidth: ['100%', '340px'] })};
  width: 100%;
`;

export const StickyBuyButtonContent = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.basic100};
  display: flex;
  justify-content: space-between;
  ${space({ py: ['10px'], px: ['10px', 0] })};
  ${flexDirection({ flexDirection: ['column', 'row'] })};
`;

export const StickyBuyButtonWrapper = styled.div`
  ${boxShadow({
    boxShadow: ['0px -4px 12px rgba(0, 0, 0, 0.15)', 'none'],
  })};
  ${({ theme }) =>
    border({
      borderBottom: ['none', `1px solid ${theme.colors.basic400}`],
    })};
`;
