import styled from 'styled-components';

export const CreditCardContainerStyles = styled.div`
  margin-top: 14px;
  text-align: center;
  margin-bottom: 32px;
`;

export const CartProductInformationStyles = styled.div`
  padding: 16px;
`;

export const SummaryContainerStyles = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
`;

export const CheckoutBoxStyles = styled.div`
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.basic200};
`;
