import React from 'react';
import { graphql } from 'gatsby';
import { CartPage } from 'components/Cart/CartPage/CartPage';
import { Block, renderCartPageBlock } from './CartPage.blocks';
import { LadderCartPageBySlugQuery } from 'graphql-types';
import { LadderApp } from '../LadderApp';

const CartPageTemplate = (props: { data: LadderCartPageBySlugQuery }) => {
  const { blocks } = props.data?.page || {};

  return (
    <LadderApp>
      <CartPage
        blocks={blocks as Block[]}
        BlockRenderer={renderCartPageBlock}
      />
    </LadderApp>
  );
};

export default CartPageTemplate;

export const query = graphql`
  query LadderCartPageBySlug($slug: String!) {
    page: sanityLadderCartPage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ...SanitySellPageSpacer
        ...LadderCartCrossSell
      }
    }
  }
`;
