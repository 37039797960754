import React from 'react';
import { logError } from 'helpers/Log';
import { useStaticQuery, graphql } from 'gatsby';
import { SanityContinuityTerm, SanityProductVariant } from 'graphql-types';

const getSubscriptionDuration = (
  variant:
    | SanityProductVariant
    | { interval: string; intervalCount: number; recurring: boolean },
  terms: SanityContinuityTerm[],
  omitPrefix?: boolean
): string => {
  if (variant?.recurring === false || !variant?.interval) {
    return '';
  }

  const interval = variant?.interval || 'UNKNOWN';
  const intervalCount = Number(variant?.intervalCount ?? 1);

  let duration = '';

  if (!terms) return '';

  const term = terms.find((term) => term.interval === interval);
  if (!term) {
    logError('Unknown interval in getSubscriptionDuration:', { interval });
    const prefix = omitPrefix ? '' : 'Every ';

    const defaultDuration = `${prefix}${intervalCount} ${
      interval[0].toUpperCase() + interval.slice(1)
    }`;
    return defaultDuration;
  }

  const rewriteRule =
    Array.isArray(term.rewrite) &&
    term.rewrite.find((r) => Number(r?.count) === intervalCount);

  if (rewriteRule && rewriteRule.text) {
    duration = rewriteRule.text;
  } else {
    duration = (intervalCount <= 1 ? term.singular : term.plural) || '';
  }

  duration = duration.replace('{count}', `${intervalCount}`);

  if (omitPrefix) {
    duration = duration.replace('Every ', '');
  }
  return duration;
};

const StaticDuration: React.FC<{
  variant: SanityProductVariant | { interval: string; intervalCount: number };
  omitPrefix?: boolean;
}> = ({ variant, omitPrefix = false }) => {
  const data = useStaticQuery(graphql`
    query SubscriptionDurationQuery {
      allSanityContinuityTerm {
        nodes {
          interval
          plural
          singular
          rewrite {
            count
            text
          }
        }
      }
    }
  `);
  return (
    <>
      {getSubscriptionDuration(
        variant,
        data.allSanityContinuityTerm.nodes,
        omitPrefix
      )}
    </>
  );
};

// Injection point for storybook builds to mock this
const MOCK_CONTINUITY_TERMS = process.env.MOCK_CONTINUITY_TERMS as any;

// TODO: Move this to GraphQL so the client doesn't need to determine duration down to the client
export const SubscriptionDuration: React.FC<{
  variant: SanityProductVariant;
  omitPrefix?: boolean;
}> = ({ variant, omitPrefix = false }) => {
  // Not passing this as props to Static duration because the useStaticQuery will throw
  // an error and can't be conditionally invoked directly
  if (MOCK_CONTINUITY_TERMS?.data) {
    return (
      <>
        {getSubscriptionDuration(
          variant,
          MOCK_CONTINUITY_TERMS.data.allSanityContinuityTerm.nodes,
          omitPrefix
        )}
      </>
    );
  }
  return <StaticDuration variant={variant} omitPrefix={omitPrefix} />;
};
