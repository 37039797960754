import { PDPPageContainer } from 'components/Pdp';
import { StickyElement } from 'components/Common/StickyElement/StickyElement';
import React, { Fragment, useContext } from 'react';
import {
  BuyButton,
  // BuyButtonDivider,
  StickyBuyButton,
  StickyBuyButtonCopy,
  StickyBuyButtonImageContainer,
  StickyBuyButtonDetail,
  StickyBuyButtonTitle,
  StickyBuyButtonContent,
  StickyBuyButtonWrapper,
} from './StickyBuyButtonCta.styles';
import { useIsBrowser } from 'utils/general';
import { ThemeContext } from 'styled-components';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';
import { BUY_FROM_AMAZON } from 'Constants';
interface StickyBuyButtonProps {
  image?: JSX.Element;
  price?: number;
  origPrice?: number;
  title?: string;
  buyUrl?: string;
  handleBuyClick: React.ReactEventHandler;
  handleBuyStickyClick: React.ReactEventHandler;
  addToCartAction?: 'checkout' | 'cart';
  textOverride?: string;
  disabled?: boolean;
}

export const StickyBuyButtonCta: React.FC<StickyBuyButtonProps> = ({
  image,
  price = 0,
  origPrice,
  title = '',
  buyUrl,
  handleBuyClick,
  handleBuyStickyClick,
  addToCartAction,
  textOverride,
  disabled = false,
}) => {
  const isCartButton = addToCartAction === 'cart';

  const theme = useContext(ThemeContext);
  const buyButtonFontSize = [
    theme.fontSizes.default,
    theme.fontSizes.default,
    theme.fontSizes.default,
  ];

  const format = getPriceFormatter('USD', 'en-US');
  const isBrowser = useIsBrowser();
  if (typeof format !== 'function') return null;
  // const formattedPrice = format(price);
  // const originalPrice =
  //   origPrice && origPrice > price ? ` (${format(origPrice)} value)` : '';

  const StickyButtonCopy = (
    <StickyBuyButtonCopy>
      {image && (
        <StickyBuyButtonImageContainer>{image}</StickyBuyButtonImageContainer>
      )}
      <StickyBuyButtonDetail>
        <StickyBuyButtonTitle>{title}</StickyBuyButtonTitle>
        {/* {formattedPrice} */}
      </StickyBuyButtonDetail>
    </StickyBuyButtonCopy>
  );

  const BuyButtonContent = textOverride || (
    <>
      {/* {formattedPrice}
      {originalPrice}
      <BuyButtonDivider /> */}
      {!isCartButton ? 'Buy Now' : BUY_FROM_AMAZON}
    </>
  );

  return (
    <Fragment key={isBrowser.toString()}>
      <StickyElement
        position="topDesktop"
        desktopEnabled={true}
        backgroundColor={theme.colors.basic100}
      >
        <StickyBuyButtonWrapper>
          <PDPPageContainer>
            <StickyBuyButtonContent>
              {StickyButtonCopy}
              <StickyBuyButton
                variant={disabled ? 'disabled' : 'primary'}
                href={buyUrl}
                onClick={handleBuyStickyClick}
                fontSize={buyButtonFontSize}
              >
                {BuyButtonContent}
              </StickyBuyButton>
            </StickyBuyButtonContent>
          </PDPPageContainer>
        </StickyBuyButtonWrapper>
      </StickyElement>
      <BuyButton
        variant={disabled ? 'disabled' : 'primary'}
        href={buyUrl}
        onClick={handleBuyClick}
        fontSize={buyButtonFontSize}
      >
        {BuyButtonContent}
      </BuyButton>
    </Fragment>
  );
};
