import styled from 'styled-components';
import chevronDownSVG from 'svg/chevron-down.svg';

export const StyledFieldSelectWrap = styled.div`
  width: 100%;
`;

export const StyledFieldSelectContainer = styled.div`
  position: relative;
  &:after {
    background-color: ${({ theme }) => theme.select.primary};
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-image: url(${chevronDownSVG.url});
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-repeat: no-repeat;
    content: ' ';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
  }
`;

export const StyledFieldSelect = styled.select<{
  small?: boolean;
  withPlaceholder?: boolean;
  isBold?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.basic100};
  padding: ${(props) => (props.small ? '0 25px 0 10px' : '0 25px 0 10px')};
  height: ${(props) => (props.small ? '30px' : '40px')};
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  border-radius: 4px;
  color: ${(props) =>
    props.withPlaceholder
      ? props.theme.colors.basic600
      : props.theme.colors.default};
  font-size: 16px;
  width: 100%;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  box-shadow: none;
  transition: border-color 0.3s;
  font-weight: ${(props) =>
    props.withPlaceholder || props.isBold === false ? 'normal' : 'bold'};
  &:focus {
    border-color: ${({ theme }) => theme.colors.default};
  }
`;
